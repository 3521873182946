/*
 * This is for global css usage.
 */

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@300;400;500;700&display=swap'); /* Noto Sans TC */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap'); /* Roboto */
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+TC:wght@700&display=swap'); /* Noto Serif TC */

* {
	box-sizing: border-box;
	outline: none;
	font-family: 'Noto Sans TC', 'Jinxuan', 'Roboto', serif;
	font-weight: normal;
	color: var(--textBlack);
	font-size: 16px;
	-webkit-tap-highlight-color: transparent;
}

body {
	margin: 0;
	overflow-x: hidden;
}

a {
	text-decoration: none;
}

li {
	list-style: none;
}

button {
	padding: 0;
	border: none;
	background-color: transparent;
	cursor: pointer;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	font-weight: normal;
}

textarea,
input {
	padding: 0;
	border: none;
	background-color: transparent;
	border-radius: 0;
	/* stylelint-disable-next-line property-no-vendor-prefix */
	-webkit-appearance: none;
}

ol {
	margin: 0;
	padding: 0;
}

/* stylelint-disable */
:global(body.no-scroll) {
	overflow: hidden;
}
/* stylelint-enable */

html {
	&[class='jf-reloading'] { /* _jf.flush() 執行中不顯示 */
		& body {
			/* opacity: 0;
			visibility: hidden; */
		}
	}

	&[class='jf-active'], /* 如果處理成功，直接顯示 */
	&[class='jf-inactive'] { /* 如果處理失敗，直接顯示 */
		& body {
			opacity: 1;
			visibility: visible;
		}
	}
}
